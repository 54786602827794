.menu {
  display: flex;
  position: fixed;
  top: 15px;
  right: 50px;
  z-index: 99;
  font-weight: 500;
  font-size: 1.1rem;
  background-color: black;
  border-radius: 15px;
}

.menuItem {
  padding: 15px;
  margin: 0px;
}
.menuItem:hover {
  cursor: pointer;
  text-decoration: underline;
}

@media only screen and (max-width: 760px) {
  .menu {
    right: auto;
    justify-content: center;
    width: 95%;
    top: 5px;
    margin: 2px;
  }
  .menuItem {
    padding: 7px;
  }
}
