@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,500;1,300&display=swap');

html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  color: aliceblue;
  background-color: black;
  font-family: 'Open Sans', sans-serif;
}
h1 {
  font-style: italic;
}

a:link {
  color: aliceblue;
  text-decoration: none;
}
a:visited {
  color: aliceblue;
  text-decoration: none;
}
a:hover {
  text-decoration: underline;
}
a:active {
  color: grey;
}
