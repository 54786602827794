canvas {
  width: 100%;
  height: 620px;
}
.skills {
  margin-left: 5%;
  font-weight: 400;
}

@media only screen and (max-width: 760px) {
  canvas {
    width: 100%;
    height: 440px;
  }
}
