.projectsContainer {
  margin: 0;
  background-color: #8b8b8b;
  padding-bottom: 40px;
}
.projectsContainer h1 {
  margin-top: 0;
  padding-top: 5px;
}
.projectsContainer h2 {
  margin: 0;
  font-weight: 500;
}

.projects {
  margin-left: 5%;
  font-weight: 400;
}

.projectsInfo {
  width: 35%;
  padding-top: 55px;
  margin: auto;
  font-weight: 400;
}

.projectItems {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.projectItem {
  margin: 15px;
  width: 270px;
}

.projectHero {
  width: 424px;
  height: 406px;
  margin: auto;
  background-color: black;
}

.projectImage {
  width: 270px;
  height: 270px;
}

@media only screen and (max-width: 760px) {
  .projectHero {
    display: none;
  }
  .projectsInfo {
    width: 70%;
  }
}
