.contactContainer {
  font-weight: 400;
  background-color: #2e2e2e;
  margin: 0;
}
.contactContainer h1 {
  margin-top: 0;
  margin-left: 5%;
  padding-top: 5px;
}

.contact {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
  padding-top: 100px;
  padding-bottom: 150px;
}
.contactItem {
  width: 500px;
  text-align: center;
  padding: 5px;
}

.tagLine {
  font-weight: 300;
  font-style: italic;
  font-size: 2.5rem;
}

.bird {
  width: 20px;
  height: 16px;
}
