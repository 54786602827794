.home {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
}
.homeItem {
  width: 540px;
}

.tagLine {
  font-weight: 300;
  font-style: italic;
  font-size: 2.5rem;
}

.nxdecLogo {
  width: 340px;
  height: 340px;
}

@media only screen and (max-width: 760px) {
  .home {
    margin-top: 55px;
  }
  .homeItem {
    width: 150px;
    padding: 10px;
  }
  .nxdecLogo {
    width: 150px;
    height: 150px;
  }
  .tagLine {
    font-size: 1.2rem;
  }
}
