.designsContainer {
  margin: 0;
  background-color: #8b8b8b;
  padding-bottom: 40px;
}
.designsContainer h1 {
  margin-top: 0;
  padding-top: 5px;
}
.designsContainer h2 {
  margin: 0;
  font-weight: 500;
}

.designs {
  margin-left: 5%;
  font-weight: 400;
}

.designsInfo {
  width: 35%;
  padding-top: 55px;
  margin: auto;
  font-weight: 400;
}

.designItems {
  margin: 2%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.designItem {
  margin: 15px;
}

.designImage {
  width: 540px;
  height: 540px;
}

@media only screen and (max-width: 760px) {
  .designsInfo {
    width: 70%;
  }
  .designImage {
    width: 340px;
    height: 340px;
  }
}
